export const uiRedux = {
  headerSize: "UPDATE_HEADER_SIZE",
  footerSize: "UPDATE_FOOTER_SIZE",
  height: "UPDATE_HEIGHT",
  width: "UPDATE_WIDTH",
  scroll: "UPDATE_SCROLL",
  drawer: "UPDATE_DRAWER",
  search: "UPDATE_SEARCH",
  cartVisible: "UPDATE_CART_VISIBLE",
  wishlistVisible: "UPDATE_WiSHLIST_VISIBLE",
  signUpVisible: "UPDATE_SIGNUP_VISIBLE",
  signInVisible: "UPDATE_SIGNIN_VISIBLE",
  userFillUp: "UPDATE_USER_FILLUP",
};

export const apiRedux = {
  userData: "UPDATE_USER_DATA",
  brands: "UPDATE_BRANDS",
  categories: "UPDATE_CATEGORIES",
  subcategories: "UPDATE_SUBCATEGORIES",
  scents: "UPDATE_SCENTS",
  cart: "UPDATE_CART",
  wishlist: "UPDATE_WISHLIST",
};
