import {
  FacebookOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  InstagramOutlined,
  MailOutlined,
  OrderedListOutlined,
  PhoneOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import URLS from "./nav";

const socialLinks = {
  facebook: {
    url: "",
    label: "Facebook",
    icon: <FacebookOutlined />,
  },
  instagram: {
    url: "https://instagram.com/manubrotherss",
    label: "Instagram",
    icon: <InstagramOutlined />,
  },
  email: {
    url: "mailto:manubrothers09@gmail.com",
    label: "Email",
    icon: <MailOutlined />,
  },
  mobile: {
    url: "tel:+919925951350",
    label: "Mobile",
    icon: <PhoneOutlined style={{ transform: "rotatey(180deg)" }} />,
  },
};

const headerLinks = [
  { label: "Home", url: URLS.home, icon: <HomeOutlined /> },
  { label: "Shop", url: URLS.shop, icon: <ShopOutlined /> },
  { label: "About Us", url: URLS.about, icon: <InfoCircleOutlined /> },
  {
    label: "Contact Us",
    url: URLS.contact,
    icon: <PhoneOutlined style={{ transform: "rotateY(180deg)" }} />,
  },
  { label: "Blogs", url: URLS.blogs, icon: <OrderedListOutlined /> },
];

const homeCategories = [
  {
    key: "agarbatti",
    label: "Agarbatti",
    id: "643e1b417125dd88bb8d12b0,643e1b277125dd88bb8d12a0,643e1b1d7125dd88bb8d1298",
  },
  {
    key: "dhoop",
    label: "Dhoop",
    id: "643e1b6b7125dd88bb8d12c8,643e1b607125dd88bb8d12c0,643e1b587125dd88bb8d12b8",
  },
  { key: "kapoor", label: "Kapoor", id: "64ae4ff9ddd48654393ef703" },
  { key: "zipper", label: "Zipper", id: "643e1b357125dd88bb8d12a8" },
];

const constants = {
  imageBase: "/assets/images",
  imgAlt: "404 - Not Found",
  socialLinks,
  headerLinks,
  homeCategories,
};

export default constants;
