import { apiRedux } from "../constants/redux";

const state = {
  UserData: null,
  Brand: [],
  Category: [],
  SubCategory: [],
  Scents: [],
  Cart: [],
  Wishlist: [],
};

const apiReducer = (initialState = state, action) => {
  const { type, data } = action;
  switch (type) {
    case apiRedux.userData:
      return { ...initialState, UserData: data };
    case apiRedux.brands:
      return { ...initialState, Brand: data };
    case apiRedux.categories:
      return { ...initialState, Category: data };
    case apiRedux.subcategories:
      return { ...initialState, SubCategory: data };
    case apiRedux.scents:
      return { ...initialState, Scents: data };
    case apiRedux.cart:
      return { ...initialState, Cart: data };
    case apiRedux.wishlist:
      return { ...initialState, Wishlist: data };
    default:
      return initialState;
  }
};

export default apiReducer;
