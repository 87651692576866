import config from "./config";

export const milliToSeconds = (milli) => milli / 1000;

export const milliToMinutes = (milli) => milliToSeconds(milli) / 60;

export const secondsToMilli = (seconds) => seconds * 1000;

export const minutesToMilli = (minutes) => secondsToMilli(minutes * 60);

export const getDeviceId = () => window.localStorage.getItem("device-id");

export const setDeviceId = (id) => window.localStorage.setItem("device-id", id);

export const removeDeviceId = () => window.localStorage.removeItem("device-id");

export const openGoogleCallback = () => {
  window.open(`${config.apiBase}/users/google/callback`, "_self");
};
