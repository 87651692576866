import React from "react";
import constants from "../../constants/contants";

export const Fallback = () => {
  return (
    <div
      className="is-flex is-justify-content-center is-align-items-center"
      style={{ width: "100vw", height: "100vh", background: "#f2f4f4" }}
    >
      <figure className="image">
        <img
          alt={constants.imgAlt}
          src={`${constants.imageBase}/agarbatti.gif`}
        />
      </figure>
    </div>
  );
};
