import config from "../utils/config";

const URLS = {
  home: "/",
  shop: "/shop",
  about: "/about",
  contact: "/contact",
  terms: "/terms-and-condition",
  privacyPolicy: "/privacy-policy",
  returnPolicy: "/return-policy",
  shippingPolicy: "/shipping-policy",
  myAccount: "/my-account",
  myOrders: "/my-orders",
  orderSummery: "/order-summery",
  product: "/product",
  blogs: "/blogs",

  backendUsers: `${config.apiBase}/users`,
  backendOrders: `${config.apiBase}/orders`,
  backendPublic: `${config.apiBase}/public`,
  backendCart: `${config.apiBase}/cart`,
  backendWishlist: `${config.apiBase}/wishlist`,
};

export default URLS;
