import { uiRedux } from "../constants/redux";

const state = {
  headerSize: 75,
  footerSize: 0,
  drawer: false,
  scroll: 0,
  width: window.innerWidth,
  height: window.innerHeight,
  cartVisible: false,
  wishlistVisible: false,
  searchVisible: false,
  signUpVisible: false,
  signInVisible: false,
  userFillUp: false,
};

const uiReducer = (initialState = state, action) => {
  const { type, data } = action;
  switch (type) {
    case uiRedux.footerSize:
      return { ...initialState, footerSize: data };
    case uiRedux.headerSize:
      return { ...initialState, headerSize: data };
    case uiRedux.width:
      return { ...initialState, width: data };
    case uiRedux.height:
      return { ...initialState, height: data };
    case uiRedux.scroll:
      return { ...initialState, scroll: data };
    case uiRedux.drawer:
      return { ...initialState, drawer: data };
    case uiRedux.cartVisible:
      return { ...initialState, cartVisible: data };
    case uiRedux.wishlistVisible:
      return { ...initialState, wishlistVisible: data };
    case uiRedux.search:
      return { ...initialState, searchVisible: data };
    case uiRedux.signInVisible:
      return { ...initialState, signInVisible: data };
    case uiRedux.signUpVisible:
      return { ...initialState, signUpVisible: data };
    case uiRedux.userFillUp:
      return { ...initialState, userFillUp: data };
    default:
      return initialState;
  }
};

export default uiReducer;
