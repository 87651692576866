import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "bulma/bulma.sass";
import "aos/dist/aos.css";
import "./assets/styles/global.scss";
import { Provider } from "react-redux";
import store from "./reducers";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import { Fallback } from "./components/extra/fallback";
import { minutesToMilli, secondsToMilli } from "./utils/common";

const client = new QueryClient({
  refetchOnReconnect: true,
  refetchOnWindowFocus: false,
  retry: 2,
  retryDelay: secondsToMilli(30),
  cacheTime: minutesToMilli(15),
  staleTime: minutesToMilli(15),
});
const Main = lazy(() => import("./main"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={client}>
        <Suspense fallback={<Fallback />}>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: "#3d251edd",
                  colorPrimaryHover: "#3d251e",
                  colorPrimaryActive: "#3d251e",
                  colorText: "#3d251e",
                },
                Collapse: { colorBorder: "#3d251edd", colorText: "#3d251edd" },
              },
            }}
          >
            <BrowserRouter>
              <Main />
            </BrowserRouter>
          </ConfigProvider>
        </Suspense>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
