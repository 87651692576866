import { combineReducers, configureStore } from "@reduxjs/toolkit";
import apiReducer from "./api";
import uiReducer from "./ui";

const store = configureStore({
  reducer: combineReducers({
    ui: uiReducer,
    api: apiReducer,
  }),
});

export default store;
